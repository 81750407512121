import React, { Component } from 'react';
import { IonList, IonListHeader, IonItem, IonLabel } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { isDefined, forwardTo } from '../../lib/utils';
import { NormalText, SmallText } from '../../components/common';
import NoData from '../../components/noData';
import Basket from '../../lib/basket';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';

const truncate = (str, max = 15) => {
	let array = str.trim().split(' ');
	const ellipsis = array.length > max ? '...' : '';
	array = array.map((itm, i) => (i === max - 1 ? itm.replace(/,/g, '') : itm));
	return array.slice(0, max).join(' ') + ellipsis;
};

class orderList extends Component {
	itemList = (__, subCategoryItems, i) => {
		const { profile } = this.props;
		return (subCategoryItems || []).map((item, index) => {
			if (item.sku === undefined || (getConfig().flags.showSnoozedProducts ? !Basket.isProductJustEnabled(item) : !Basket.isProductEnabled(item))) {
				return <span key={item.sku + '_disabled'} />;
			}
			let image = item.itemRichData && item.itemRichData.squareImageUrl ? item.itemRichData.squareImageUrl : '';
			if (image && image.indexOf('http://') !== -1) {
				image = image.replace(/http:\/\//g, 'https://');
			}
			const prodDesc = Basket.getProductDescription(item, profile).replace(/(<([^>]+)>)/gi, '');
			return (
				<IonItem
					className={`${!Basket.isProductUnsnoozed(item) ? 'disabled-element' : ''} `}
					key={item.sku + '_' + index + i}
					onClick={() => (Basket.isProductUnsnoozed(item) ? forwardTo('/item-details', item) : null)}
				>
					<div className="order-list-item">
						<div className="item-content">
							<div className="item-content">
								<NormalText className="primary-color">{__(Basket.getProductName(item, profile))}</NormalText>
								{prodDesc ? (
									<SmallText
										tag="p"
										color="dark"
										dangerouslySetInnerHTML={{
											__html: truncate(prodDesc).replace(/(<([^>]+)>)/gi, ''),
										}}
									/>
								) : null}
							</div>
							<div className="item-price">
								<SmallText>{Basket.formatPrice(Basket.getProductCardPrice(item))}</SmallText>
								{item.originalPrice ? (
									<>
										<br />
										<SmallText className="original-price">&#163;{item.originalPrice}</SmallText>
									</>
								) : null}
							</div>
						</div>
						<div className="item-image-wrapper">
							{image && image !== '' ? (
								<>
									<div className="item-image" style={{ backgroundImage: 'url(' + image + ')' }} />
								</>
							) : (
								<>
									<div
										className="item-image"
										style={{ backgroundSize: 'contain', backgroundImage: `url(${Basket.getRestaurant()?.menu_item_placeholder_image ?? this.props.clientProfile.logo_image})` }}
									/>
								</>
							)}
						</div>
					</div>
				</IonItem>
			);
		});
	};

	drawCategoryItems = (__, category, items, breadCrumb = null, i, isGroup = false, allGroup = false) => {
		let name = category.name ? breadCrumb + category.name : breadCrumb + '';
		let drawSubCategory = isGroup ? (
			<div ref={this.props.menuRefs[`${name}_${i}`]}>
				<IonListHeader className={allGroup ? 'order-sublist-header' : 'order-sublist-header empty'}>
					<IonLabel>{name}</IonLabel>
				</IonListHeader>
				<ul className="order-list-group">{this.subCategoriesList(__, category, breadCrumb + category.name)}</ul>
			</div>
		) : (
			<>{items}</>
		);

		return <div key={category.type + '_' + name + i}>{drawSubCategory}</div>;
	};

	breadCrumb = (name) => {
		let breadCrumb = name !== '' ? name + ' | ' : name;
		return breadCrumb;
	};

	subCategoriesList = (__, category, name) => {
		if (!isDefined(category)) {
			return <NoData />;
		}
		const categoryItems = category.menuEntry || category.menuEntryGroups || null;
		let items = this.itemList(__, categoryItems);
		let breadCrumb = this.breadCrumb(name);
		if (categoryItems) {
			return categoryItems.map((subCategory, index) => {
				if (subCategory['@type'] === 'group') {
					items = this.itemList(__, subCategory.menuEntry, index);
					const allGroup = subCategory.menuEntry.some((item) => item.type !== 'group');
					return this.drawCategoryItems(__, subCategory, items, breadCrumb, index, true, allGroup);
				} else {
					items = this.itemList(__, [subCategory], index);
					return this.drawCategoryItems(__, subCategory, items, name, index, false);
				}
			});
		}
		return this.drawCategoryItems(__, category, items, name, 0);
	};

	calcHeight = () => {
		const { category } = this.props;
		let height = 0;
		if (this.ionList && this.orderListItems && isDefined(category)) {
			let orderListHeight = this.orderListItems.clientHeight;
			let lastCategory = this.ionList.childNodes[this.ionList.childNodes.length - 1];
			if (!lastCategory) {
				return 0;
			}
			let lastCategoryInnerWrap = lastCategory?.childNodes[0];
			let nodes = lastCategoryInnerWrap?.childNodes;
			let lastCategoryHeader = nodes[0];
			let lastCategoryItem = nodes[nodes.length - 1];
			if (orderListHeight && lastCategoryHeader && lastCategoryItem) {
				height = orderListHeight - (lastCategoryHeader.clientHeight + lastCategoryItem.clientHeight);
			}
		}

		return height;
	};
	render() {
		const { __, category } = this.props;
		return (
			<div className="order-list-items" onScroll={(e) => this.props.selectCategoryOnScroll(e)} ref={(ref) => (this.orderListItems = ref)}>
				<IonList lines="none" ref={(ref) => (this.ionList = ref)}>
					{this.subCategoriesList(__, category, '')}
				</IonList>
				<div style={{ height: this.calcHeight() }}></div>
			</div>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		profile: store.profile.profile,
		restaurantsUpdated: store.restaurants.restaurantsUpdated,
		basketUpdated: store.orders.basketUpdated,
		clientProfile: store.common.clientProfile,
	};
};

export default connect(mapStateToProps)(withTranslation(orderList));
