import { Capacitor } from '@capacitor/core';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { CardIO } from '@ionic-native/card-io/ngx';
import { IonAlert, IonButton, IonItem, IonList, isPlatform } from '@ionic/react';
import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import React from 'react';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { getConfig } from '../../appConfig';
import { FieldError, NormalText, Spacer, Title } from '../../components/common';
import Layout from '../../components/layout';
import Mobiscroll from '../../components/mobiscroll';
import Modal from '../../components/modal';
import Loading from '../../components/spinner';
import history from '../../history';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import Stripe from '../../lib/stripe';
import { withTranslation } from '../../lib/translate';
import { forwardTo, getLocale, goBack, isDefined, shouldAddPrepTime, validateForm } from '../../lib/utils';
import { addScannedCard, setCommonModal, showToast } from '../../store/actions';
import CheckoutPay from '../checkout/CheckoutPay';
import { checkSnoozedTimes, formatDataForTime } from '../clickAndCollect';
import './index.css';

const { SelectOption } = Mobiscroll;

const { DatePicker } = Mobiscroll;

const isWeb = () => Capacitor.platform === 'web';

const nameInputMask = Array(50).fill(/[a-z0-9A-Z ]/);

const { getMobile, setMobile, getOrderType, _getTotal, formatPrice } = Basket;

class Cards extends React.Component {
	constructor(props) {
		super(props);
		if (!getMobile() && this.props.profile && this.props.profile.mobile) {
			setMobile(this.props.profile.mobile);
		}
		this.state = {
			name: '',
			cardNumber: '',
			expiryDate: '',
			cvc: '',
			formErrors: {},
			focus: {},
			numberOfReadyElements: 0,
			focusedInput: '',
			showForm: true,
			collectedModal: false,
			driverModal: false,
			pickTime: null,
			fieldColor: '',
		};
		this.cardIO = new CardIO();
		this.androidPermissions = new AndroidPermissions();
	}
	changeTime = (selectedTime, minDT) => {
		let h = parseInt(selectedTime.split(':')[0]);
		let m = parseInt(selectedTime.split(':')[1]);
		const formattedDT = moment(minDT).hours(h).minutes(m);
		Basket.setCollectionTime(formattedDT);
	};

	setPickTime = (inst, minDT) => {
		if (inst.getVal()) {
			this.changeTime(inst.getVal(), minDT);
			this.setState({ pickTime: inst.getVal() });
		}
	};
	isFistCard = () => this.props.cards && this.props.cards.length === 1;

	addCardFromCheckout = () => (this.props.location && this.props.location.state && isDefined(this.props.location.state.addCardFromCheckout) ? this.props.location.state.addCardFromCheckout : false);

	addCardFromAccount = () => (this.props.location && this.props.location.state && isDefined(this.props.location.state.addCardFromAccount) ? this.props.location.state.addCardFromAccount : false);

	addCardFromMembership = () =>
		this.props.location && this.props.location.state && isDefined(this.props.location.state.addCardFromMembership) ? this.props.location.state.addCardFromMembership : false;

	resetState = () =>
		this.setState({ cardNumber: '', expiryDate: '', cvc: '', name: '' }, () => {
			const { cards } = this.props;
			// when user add his first card then create order instantly
			// note: this method will be called after card added
			if (this.isFistCard() && !this.addCardFromAccount() && !this.addCardFromMembership() && !this.props?.location?.state?.fromTablePay) {
				Basket.changeSelectedCard(cards[0].id);
				Basket.createOrder();
			} else if (this.props?.location?.state?.fromTablePay) {
				forwardTo('/checkout', { fromTablePay: true, cardAdded: true });
			} else {
				if (this.props.skipRedirect) {
					return;
				}
				if (this.props.location && this.props.location.state && this.props.location.state.giftVoucher) {
					history.push('/gift-vouchers', { type: true });
				} else if (this.props.location && this.props.location.state && this.props.location.state.addCardFromCheckout) {
					// skip cards list page when we going back from add card form page to checkout page
					goBack();
				} else if (this.addCardFromAccount()) {
					// skip cards list page when we going back from add card form page to account page
					goBack();
				} else if (this.addCardFromMembership()) {
					// skip cards list page when we going back from add card form page to account page
					goBack();
				} else {
					forwardTo('/cards');
				}
			}
		});

	createCard = (card) => {
		const { dispatch } = this.props;
		const { name } = this.state;
		// dispatch(loading(true))

		try {
			Stripe.getStripeInstance()
				.createCardToken(card)
				.then((token) => {
					const cardToken = token.id;
					dispatch(
						addScannedCard(name, cardToken, {
							cb: () => {
								this.resetState();
							},
						}),
					);
				})
				.catch((error) => {
					dispatch(showToast(error, 'warning'));
				});
		} catch (e) {
			// dispatch(loading(false))
		}
	};

	validateCardForm = (field) => {
		const formErrors = validateForm(
			{
				cardNumber: { type: 'cardNumber', required: true },
				expiryDate: { required: true },
				cvc: { required: true },
			},
			this.state,
		);

		const errorsLength = Object.keys(formErrors).length;
		if (errorsLength !== 0 && !field) {
			this.setState({ formErrors });
		} else if (errorsLength !== 0 && field) {
			this.setState({
				formErrors: {
					...this.state.formErrors,
					[field]: formErrors[field],
				},
			});
		} else if (errorsLength === 0) {
			this.setState({ formErrors });
		}
		return formErrors;
	};
	componentDidMount() {
		const { theme, colors } = this.props.clientStyles;
		if (theme == 'dark') {
			this.setState({ fieldColor: '#ffffff' });
		} else if (theme == 'light') {
			this.setState({ fieldColor: '#000000' });
		} else {
			if (colors['--okx-textfield-color']) {
				this.setState({ fieldColor: colors['--okx-textfield-color'] });
			} else {
				this.setState({ fieldColor: '#000000' });
			}
		}
	}
	addPaymentCard = (event) => {
		event.preventDefault();
		const { cardNumber, expiryDate, cvc, name } = this.state;
		const { stripe, elements } = this;
		const { dispatch, __ } = this.props;

		if (Capacitor.platform === 'web') {
			if (!stripe || !elements) {
				return;
			}
			const that = this;
			const cardNumberElement = elements.getElement(CardNumberElement);
			stripe.createToken(cardNumberElement).then(function (result) {
				if (result.error) {
					dispatch(showToast(__(result.error.message), 'warning'));
				} else {
					if (result.token && result.token.card) {
						const cardToken = result.token.id;
						dispatch(
							addScannedCard(name, cardToken, {
								cb: () => {
									that.resetState();
								},
							}),
						);
					}
				}
			});
		} else {
			const formErrors = this.validateCardForm();

			if (Object.keys(formErrors).length !== 0) {
				this.setState({ formErrors });
			} else {
				const arr = expiryDate.split('/');
				const month = arr[0];
				const year = arr[1].length === 2 ? arr[1] : arr[1].length === 4 ? arr[1].substr(2, 4) : arr[1];
				const card = {
					number: (cardNumber + '').replace(/\s+/g, ''),
					exp_month: month,
					exp_year: year,
					cvc: cvc,
				};
				this.createCard(card);
			}
		}
	};

	scandCard = () => {
		const that = this;
		const { dispatch, __ } = this.props;
		return this.cardIO
			.canScan()
			.then((res) => {
				if (res) {
					let options = {
						requireExpiry: true,
						requireCVV: true,
						requirePostalCode: false,
					};
					this.cardIO.scan(options).then((card) => {
						that.setState({
							cardNumber: card.cardNumber,
							expiryDate: card.expiryMonth + '/' + card.expiryYear,
							cvc: card.cvv,
						});
					});
				} else {
					dispatch(showToast(__('You need to give this app permission to use the camera in your phone settings'), 'warning'));
				}
			})
			.catch(function () {
				dispatch(showToast(__('Scan card problem'), 'warning'));
			});
	};

	handleScanCard = () => {
		const { dispatch, __ } = this.props;
		if (isPlatform('android')) {
			this.androidPermissions
				.checkPermission(this.androidPermissions.PERMISSION.CAMERA)
				.then((result) => {
					if (result.hasPermission) {
						this.scandCard();
					} else {
						return this.androidPermissions
							.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
							.then((res1) => {
								if (!res1.hasPermission) {
									dispatch(showToast(__('You need to give this app permission to use the camera in your phone settings'), 'warning'));
								}
							})
							.catch(() => {
								dispatch(showToast(__('You need to give this app permission to use the camera in your phone settings'), 'warning'));
							});
					}
				})
				.catch(() => {
					return this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA);
				});
		} else {
			//for iOS
			this.scandCard();
		}
	};

	handleInput = (inputName, value) => this.setState({ [inputName]: value });

	setInputFocus = (inputName, flag) => this.setState({ focus: { ...this.state.focus, [inputName]: flag } });

	getInputClass = (inputName) => {
		const base = 'item-interactive item-input item-has-placeholder item md in-list ion-focusable hydrated masked-input ';
		return base + (this.state.focus[inputName] ? 'item-has-focus' : '');
	};

	elementReady = () => this.setState({ numberOfReadyElements: this.state.numberOfReadyElements + 1 });

	setFocusedInput = (inputName = '') => this.setState({ focusedInput: inputName });

	formatSelectedValue = () => {
		let selectedDate = this.state.expiryDate;
		if (selectedDate) {
			const arr = selectedDate.split('/');
			const month = parseInt(arr[0], 10) - 1;
			const year = parseInt(arr[1], 10);
			selectedDate = new Date();
			selectedDate.setMonth(month);
			selectedDate.setYear(year);
		}
		return selectedDate;
	};

	backHandler = () => {
		if (this.props.location && this.props.location.state && this.props.location.state.skipContactDetails) {
			// skip cards list page when we going back from add card form page to account page
			forwardTo('/order-summary', { skipBackToThePreviousPage: true });
		} else if (this.props.location && this.props.location.state && this.props.location.state.addCardFromAccount) {
			forwardTo('/cards', { addCardFromAccount: true });
		} else if (this.props.location && this.props.location.state && this.props.location.state.addCardFromCheckout && this.props.location.state.giftVoucher) {
			forwardTo('/gift-vouchers', { addCardFromCheckout: true, type: 'send-voucher' });
		} else if (this.props.location && this.props.location.state && this.props.location.state.addCardFromCheckout) {
			goBack(-1);
		} else if (this.addCardFromMembership()) {
			goBack();
		} else if (this.props.location.pathname === '/card-add') {
			forwardTo('/order');
		} else {
			goBack();
		}
	};

	showCollectedModal(modalOpen) {
		this.setState({ collectedModal: modalOpen });
	}

	showDriverModal(modalOpen) {
		this.setState({ driverModal: modalOpen });
	}
	goToPreviousPage() {
		if (Basket.bill_data.pay_method == '0') {
			forwardTo('/table-overview');
		} else if (Basket.bill_data.pay_method == '1') {
			forwardTo('/split-bill');
		} else if (Basket.bill_data.pay_method == '2') {
			forwardTo('/split-bill-by-amount');
		} else if (Basket.bill_data.pay_method == '3') {
			forwardTo('/split-bill-by-items');
		}
	}
	render() {
		const { __, loading, profile, cards, clientSecret, dispatch, location, restaurants, deliveryTimeModalOpen, subscription } = this.props;
		const { pickTime, name, formErrors, cardNumber, cvc, numberOfReadyElements, focusedInput, showForm, collectedModal, driverModal } = this.state;
		const isVisible = this.addCardFromAccount() || (cards && cards.length > 0);
		const isButtonVisible = this.addCardFromAccount() || showForm || this.addCardFromCheckout();
		let orderType = '';
		let price = this.addCardFromMembership() && subscription ? formatPrice(subscription.monthly_cost_inc_vat / 100) : _getTotal();
		let store = '';
		let snoozedTimes = '';
		let minDT = '';
		let timePickerOptions = [];
		if (!this.props?.location?.state?.giftVoucher && !this.props?.location?.state?.addCardFromAccount && !this.addCardFromMembership()) {
			orderType = getOrderType();
			store = restaurants.find((restaurant) => restaurant.id === Basket.getRestaurant()?.id);
			if (orderType === 'Table') {
				snoozedTimes = [];
			} else {
				snoozedTimes = checkSnoozedTimes(store, Basket.order_type); // TODO Update
			}
			minDT = moment();
			if (Basket.getDeliveryOption()?.id === 'charter-delivery') {
				if (store && isDefined(store.charter_delivery_order_slot_lead_time)) {
					minDT.add(store.charter_delivery_order_slot_lead_time, 'minutes');
				}
			} else {
				if (store && isDefined(store.order_slot_lead_time) && shouldAddPrepTime()) {
					minDT.add(store.order_slot_lead_time, 'minutes');
				}
			}

			timePickerOptions = formatDataForTime(Basket.getRestaurant(), minDT, Basket.getRestaurant()?.id, Basket.getDeliveryOption()?.id === 'charter-delivery' ? true : false, false, snoozedTimes);
		} else {
			if (!Basket.is_gift && this.props?.location?.state?.giftVoucher) {
				Basket.setCollectionTime(null);
				Basket.addToBasket({
					item: {
						productPrice: this.props?.location?.state?.giftVoucher / 100,
						productName: 'Voucher',
					},
					quantity: 1,
				});
				Basket.setGift(this.props.location.state.giftVoucher);
				Basket.setRestaurant(restaurants.find((restaurant) => restaurant.name === 'Vouchers'));
			}
		}
		const CardNameInput = (
			<IonItem lines="none" className={'input-field-wrapper ' + this.getInputClass('name')}>
				<MaskedInput
					mask={nameInputMask}
					className="payment-card-input"
					placeholder={__('E.g. Personal Account')}
					guide={false}
					onChange={(e) => {
						this.handleInput('name', e.target.value);
					}}
					onFocus={() => {
						this.setInputFocus('name', true);
					}}
					onBlur={() => {
						this.setInputFocus('name', false);
					}}
					value={name}
				/>
			</IonItem>
		);

		const inputStyle = { style: { base: { fontSize: '14px', color: this.state.fieldColor } } };
		const showStripeLoading = numberOfReadyElements < 3 && loading === 0 && Capacitor.platform === 'web';

		const pageContent = (
			<Loading>
				<Layout color="transparent" title={getConfig().flags.paymentDisabled ? __('Pay your order') : __('Your Cards')} backHandler={this.backHandler} scrollY={false} headerWithTitle={true}>
					<form onSubmit={this.addPaymentCard}>
						<div className="flex-row-wrapper absolute-content">
							{isVisible ? null : showForm ? null : <Title>{__('Checkout')}</Title>}
							<div style={isVisible ? { display: 'none' } : showForm ? { display: 'none' } : { display: 'block' }}>
								<div tabIndex="-1"></div>
								<IonButton fill="clear" className="link underlined" color="dark" onClick={() => this.setState({ showForm: true })}>
									{__('Pay with card')}
								</IonButton>
							</div>
							{!getConfig().flags.paymentDisabled && (
								<div style={isVisible ? { display: 'block' } : showForm ? { display: 'block' } : { display: 'none' }} className="scrollable-y add-card-scroll">
									<Title className="web-only">{__('Your Cards')}</Title>
									{/* <Spacer size={3} /> */}

									{showStripeLoading ? (
										<Loading additionalLoadingCondition={showStripeLoading} transparent transparentForce>
											<span />
										</Loading>
									) : null}
									<IonList className="card-list">
										{Capacitor.platform !== 'web' ? (
											<>
												{CardNameInput}
												<IonItem lines="none" className={'input-field-wrapper ' + this.getInputClass('cardNumber')}>
													<MaskedInput
														mask={[
															/\d/,
															/\d/,
															/\d/,
															/\d/,
															' ',
															/\d/,
															/\d/,
															/\d/,
															/\d/,
															' ',
															/\d/,
															/\d/,
															/\d/,
															/\d/,
															' ',
															/\d/,
															/\d/,
															/\d/,
															/\d/,
															' ',
															/\d/,
															/\d/,
															/\d/,
															/\d/,
														]}
														className="payment-card-input"
														placeholder={__('Card Number')}
														guide={false}
														onChange={(e) => {
															this.handleInput('cardNumber', e.target.value);
														}}
														onFocus={() => {
															this.setInputFocus('cardNumber', true);
														}}
														onBlur={() => {
															this.setInputFocus('cardNumber', false);
														}}
														value={cardNumber}
														type="tel"
													/>
												</IonItem>
												{formErrors.cardNumber ? <FieldError className="field-error" value={__(formErrors.cardNumber)} /> : null}
												<div className="flex-col-wrapper">
													<IonItem>
														<DatePicker
															className="data-picker-input"
															display="bottom"
															setText={__('Done')}
															cancelText={__('Cancel')}
															value={this.formatSelectedValue()}
															onSet={(e, a) => this.handleInput('expiryDate', a.element.value)}
															min={moment()}
															placeholder={__('Expiry date')}
															inputStyle="box"
															dateFormat="mm/yyyy"
															dateWheels="mm-MMyyyy"
															lang={profile.locale}
														/>
													</IonItem>
													<div className="flex-spacer" />
													<div>
														<IonItem className={this.getInputClass('cvc')}>
															<MaskedInput
																mask={[/\d/, /\d/, /\d/, /\d/]}
																className="payment-card-input"
																placeholder={__('CVV')}
																guide={false}
																onChange={(e) => {
																	this.handleInput('cvc', e.target.value);
																}}
																onFocus={() => {
																	this.setInputFocus('cvc', true);
																}}
																onBlur={() => {
																	this.setInputFocus('cvc', false);
																}}
																value={cvc}
																type="tel"
															/>
														</IonItem>
														{formErrors.cvc ? <FieldError className="field-error" value={__(formErrors.cvc)} /> : null}
													</div>
												</div>
											</>
										) : (
											<>
												<div className="input-field-container">
													<NormalText>{__('Card Details')}</NormalText>{' '}
													<div
														style={{
															display: numberOfReadyElements >= 3 ? 'block' : 'none',
														}}
													>
														<IonItem lines="none" className="input-field-wrapper" style={{ marginBottom: '10px' }}>
															<CardNumberElement
																className={'web-stripe-input ' + (focusedInput === 'cardNumber' ? 'web-stripe-input-active' : '')}
																options={{ ...inputStyle }}
																onReady={this.elementReady}
																onFocus={() => this.setFocusedInput('cardNumber')}
																onBlur={() => this.setFocusedInput()}
															/>
														</IonItem>
														<div className="flex-col-wrapper">
															<IonItem lines="none" className="input-field-wrapper" style={{ marginBottom: '10px' }}>
																<CardExpiryElement
																	className={'web-stripe-input ' + (focusedInput === 'expiry' ? 'web-stripe-input-active' : '')}
																	options={{ ...inputStyle }}
																	onReady={this.elementReady}
																	onFocus={() => this.setFocusedInput('expiry')}
																	onBlur={() => this.setFocusedInput()}
																/>
															</IonItem>
															<div className="flex-spacer" />
															<IonItem lines="none" className="input-field-wrapper" style={{ marginBottom: '10px' }}>
																<CardCvcElement
																	className={'web-stripe-input ' + (focusedInput === 'cvc' ? 'web-stripe-input-active' : '')}
																	options={{ ...inputStyle }}
																	onReady={this.elementReady}
																	onFocus={() => this.setFocusedInput('cvc')}
																	onBlur={() => this.setFocusedInput()}
																/>
															</IonItem>
														</div>
													</div>
													<div className="input-field-container">
														<NormalText>{__("Card Label (e.g. 'Personal Amex')")}</NormalText>
														{CardNameInput}
													</div>
												</div>
											</>
										)}
									</IonList>
									<Spacer size={1} />
									{this.props.cards.length > 0 && this.props.profile.is_guest && (
										<IonButton expand="block" color="primary" fill="clear" className="link underlined" onClick={() => forwardTo('/checkout')}>
											{__('Try again with previously added card')}
										</IonButton>
									)}
								</div>
							)}
							<div className="flex-min">
								{getConfig().flags.payOnCollection &&
								(Basket.getDeliveryOption()?.id == 'scheduled-collection' || Basket.getDeliveryOption()?.id == 'collection') &&
								!this.addCardFromAccount() &&
								cards &&
								cards.length === 0 ? (
									<IonButton onClick={() => this.showCollectedModal(true)} expand="block" color="primary">
										{`${__('Pay')} ${price} ${__('On Collection')}`}
									</IonButton>
								) : null}
								{getConfig().flags.payTheDriver && orderType === 'Delivery' && !this.addCardFromAccount() && cards && cards.length === 0 ? (
									<IonButton onClick={() => this.showDriverModal(true)} expand="block" color="primary">
										{`${__('Pay')} ${price} ${__('To The Driver')}`}
									</IonButton>
								) : null}

								{!getConfig().flags.paymentDisabled && (
									<>
										{isButtonVisible ? null : isWeb() ? (
											<CheckoutPay
												isBillPay={this.props?.location?.state?.fromTablePay ? true : false}
												clientSecret={clientSecret}
												dispatch={dispatch}
												__={__}
												location={location}
												profile={profile}
											/>
										) : null}

										{profile.is_guest ? (
											isWeb() ? (
												<CheckoutPay
													isBillPay={this.props?.location?.state?.fromTablePay ? true : false}
													clientSecret={clientSecret}
													dispatch={dispatch}
													__={__}
													location={location}
													profile={profile}
												/>
											) : null
										) : null}
										<div tabIndex="-1"></div>
										{isButtonVisible ? (
											<IonButton type="submit" color="primary" expand="block">
												{(cards && cards.length === 0 && !this.addCardFromAccount()) || this.props.profile?.is_guest
													? `${__('Pay')} - ${
															this.props?.location?.state?.fromTablePay
																? Basket.formatPrice((Basket.table_payment_amount + Basket.bill_pay_service_charge_applied) / 100)
																: price
													  }`
													: __('Continue')}
											</IonButton>
										) : null}
									</>
								)}

								{!this.props?.location?.state?.fromTablePay && (
									<>
										{getConfig().prebuildPayment?.isVisible ? (
											<IonButton
												style={{ marginBottom: '10px' }}
												className="uppercase okx-font-secondary"
												onClick={() => {
													Basket.createStripeOrder(getConfig().prebuildPayment?.label);
												}}
												expand="block"
											>
												{__(getConfig().prebuildPayment?.label)}
											</IonButton>
										) : null}
									</>
								)}
							</div>
							{profile.is_guest && (
								<IonButton
									expand="block"
									fill="clear"
									color="primary"
									className="link underlined"
									onClick={() => {
										this.props?.location?.state?.fromTablePay ? this.goToPreviousPage() : forwardTo('/order');
									}}
								>
									{this.props?.location?.state?.fromTablePay ? __('Cancel payment') : __('Cancel order')}
								</IonButton>
							)}
						</div>
					</form>
				</Layout>
				<Modal className={'delivery-time-modal'} isOpen={deliveryTimeModalOpen} onDidDismiss={() => dispatch(setCommonModal('deliveryTimeModalOpen', false))}>
					<Title>{__('Select Collection Time')}</Title>
					<NormalText>{__('Please select different time')}</NormalText>
					<Spacer />
					<SelectOption
						display="inline"
						onSet={(e, inst) => this.setPickTime(inst, minDT)}
						data={timePickerOptions}
						label="Location"
						value={pickTime}
						inputStyle="box"
						placeholder={__('Select Collection Time')}
						setText={__('OK')}
						cancelText={__('Cancel')}
						onInit={() => {
							if (timePickerOptions.length > 0) {
								const firstAvailableTime = timePickerOptions.find((i) => !i.disabled);
								if (!pickTime && firstAvailableTime && pickTime !== firstAvailableTime.value) {
									this.changeTime(firstAvailableTime.value, minDT);
								}
							}
						}}
					/>
					<Spacer />
					<IonButton
						expand="block"
						color="primary"
						className="uppercase okx-font-secondary"
						onClick={() => {
							forwardTo('/checkout');
							dispatch(setCommonModal('deliveryTimeModalOpen', false));
						}}
					>
						{__('Continue')}
					</IonButton>
				</Modal>
				<IonAlert
					isOpen={collectedModal}
					onDidDismiss={() => this.showCollectedModal(false)}
					header={__('Pay on Collection')}
					message={__('You agree to pay the amount shown before collecting your order. Earned loyalty will be updated after payment.')}
					buttons={[
						{
							text: __('Cancel'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.showCollectedModal(false),
						},
						{
							text: __('Confirm'),
							handler: () => {
								Basket.createOrder('collectedPay');
								this.showCollectedModal(false);
							},
						},
					]}
				/>
				<IonAlert
					isOpen={driverModal}
					onDidDismiss={() => this.showDriverModal(false)}
					header={__('Pay to the Driver')}
					message={__('You agree to pay the driver the amount outstanding on delivery. Earned loyalty will be updated  after payment.')}
					buttons={[
						{
							text: __('Cancel'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.showDriverModal(false),
						},
						{
							text: __('Confirm'),
							handler: () => {
								Basket.createOrder('driverPay');
								this.showDriverModal(false);
							},
						},
					]}
				/>
			</Loading>
		);

		return Capacitor.platform === 'web' ? (
			<>
				<Elements stripe={Stripe.getStripeInstance()} options={{ locale: getLocale(this.props.profile) }}>
					<ElementsConsumer>
						{({ elements, stripe }) => {
							this.elements = elements;
							this.stripe = stripe;
							return pageContent;
						}}
					</ElementsConsumer>
				</Elements>
				<Modal className={'delivery-time-modal'} isOpen={deliveryTimeModalOpen} onDidDismiss={() => dispatch(setCommonModal('deliveryTimeModalOpen', false))}>
					<Title>{__('Select Collection Time')}</Title>
					<NormalText>{__('Please select different time')}</NormalText>
					<Spacer />
					<SelectOption
						display="inline"
						onSet={(e, inst) => this.setPickTime(inst, minDT)}
						data={timePickerOptions}
						label="Location"
						value={pickTime}
						inputStyle="box"
						placeholder={__('Select Collection Time')}
						setText={__('OK')}
						cancelText={__('Cancel')}
						onInit={() => {
							if (timePickerOptions.length > 0) {
								const firstAvailableTime = timePickerOptions.find((i) => !i.disabled);
								if (!pickTime && firstAvailableTime && pickTime !== firstAvailableTime.value) {
									this.changeTime(firstAvailableTime.value, minDT);
								}
							}
						}}
					/>
					<Spacer />
					<IonButton
						expand="block"
						color="primary"
						className="uppercase okx-font-secondary"
						onClick={() => {
							forwardTo('/checkout');
							dispatch(setCommonModal('deliveryTimeModalOpen', false));
						}}
					>
						{__('Continue')}
					</IonButton>
				</Modal>
			</>
		) : (
			pageContent
		);
	}
}

const mapStateToProps = (store) => {
	const selectedSubscriptionId = store.orders.selectedSubscription;
	const subscriptions = store.subscription.subscriptions;
	const subscription = subscriptions.find((subscription) => subscription?.id === selectedSubscriptionId?.id);
	return {
		profile: store.profile.profile,
		loading: store.common.loading,
		cards: store.orders.cards || [],
		restaurants: store.restaurants.restaurants || [],
		clientSecret: store.orders.clientSecret,
		deliveryTimeModalOpen: store.common.deliveryTimeModalOpen,
		subscription: subscription,
		clientStyles: store.common.clientStyles,
	};
};

export default connect(mapStateToProps)(withTranslation(Cards));
